import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../components/breadcrumb'
import Heading from '../components/heading'
import Image from '../components/image'
import Layout from '../components/layout'
import Link from '../components/link'
import List from '../components/list'
import ListItem from '../components/list-item'
import Paragraph from '../components/paragraph'
import SEO from '../components/seo'


export const frontmatter = {
  body: 'Brands',
  slug: '/brands/',
  title: 'Brands',
}

export const query = graphql`
  query BrandsPageQuery($genderId: PostGraphile_Gender!) {
    ...SiteInfo
    imageMain: file(relativePath: { eq: "brands.jpg" }) { ...ImageMain }
    results: postgres {
      brands: allBrandsList(
        filter: { genders: { contains: [ $genderId ] }},
        orderBy: NAME_ASC,
      ) {
        ...Brand
      }
    }
  }
`

export default ({ data }) => {
  const { brands } = data.results
  const { links, meta } = data.site.siteMetadata
  const { entity } = meta
  const imageMain = data.imageMain.childImageSharp.fixed

  const page = 'Brands'
  const title = [entity.singular, page].join(' ')
  const description = `
    Perfumery is ancient, having started at the dawn of civilized society
    thousands of years ago. It wasn't until the end of the 19th Century
    that brands and companies began producing what we know as modern
    Perfumery, leaving us iconic examples such as Chanel No. 5, Shalimar,
    and Angel. By the middle of the 20th Century, luxury fashion houses
    began creating fragrances to accent their clothing and luggage products.
  `
  const keywords = title.split(/\W+/)
  const trail = [
    {
      name: page,
      slug: links.brands,
    },
  ]
  const schema = {
    brands,
    breadcrumb: trail,
    description,
    image: imageMain.src,
    keywords,
    name: title,
    slug: links.brands,
  }

  return (
    <Layout>
      <SEO
        description={description}
        keywords={keywords}
        schema={schema}
        title={title}
      />
      <Breadcrumb trail={trail} />

      <Heading>{title}</Heading>

      <Image
        alt={title}
        credit="brands.jpg"
        fixed={imageMain}
      />

      <Paragraph>{description}</Paragraph>

      <List>
        {brands.map(brand => (
          <ListItem key={brand.id}>
            <Link href={brand.slug}>
              {brand.name}
            </Link>
          </ListItem>
        ))}
      </List>

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
